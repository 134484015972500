import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Products", path: "/Projects" },
    { name: "Services", path: "/Service" },
    { name: "References", path: "/References" },
    { name: "Contact Us", path: "/Contact" },
  ];

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-40 transition-all duration-300 ${
        isScrolled ? "bg-slate-900 shadow-lg" : "bg-slate-800/70 backdrop-blur-md"
      }`}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-26">
          <Link to="/" className="flex-shrink-0">
            <img src="/assets/logoo.png" alt="Logo" className="h-20 w-auto" /> {/* Reduced height from h-8 to h-6 */}
          </Link>
          
          <div className="hidden lg:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {navItems.map((item) => (
             <Link
             key={item.name}
             to={item.path}
             className={`px-3 py-1 rounded-3xl text-sm font-medium ${
               location.pathname === item.path
                 ? "border-2 border-amber-500 text-white shadow-[0_-5px_15px_-3px_rgba(255,191,0,0.7)]"
                 : "text-gray-300"
             } transition-all duration-300`}
           >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          
          <div className="lg:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  location.pathname === item.path
                    ? "bg-slate-700 text-white"
                    : "text-gray-300 hover:bg-slate-700 hover:text-white"
                } transition-colors duration-200`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
      
      <div className="border-b-2 border-yellow-500 w-full"></div>
    </nav>
  );
};

export default Navbar;