import React from "react";
import Reitems from "./ReItems";
import TestimonialCarousel from "./testminonial";

const References = () => {
  return (
    <div className="overflow-hidden">
      <div
        className="flex items-center justify-center md:h-[98vh] h-[60vh] bg-cover bg-center relative"
        style={{ backgroundImage: "url('/assets/references.jpeg')" }}
      >
        {/* <div className="flex flex-col items-start mt-40 text-white">
          <h1 className="text-[65px] font-extrabold animate-bounce animate-infinite">
            References
          </h1>
        </div> */}
      </div>

      <div>
        <h1 className="text-center text-3xl text-amber-500 font-bold py-12">
          Client Testimonials
        </h1>
        <TestimonialCarousel />
      </div>

      <br />
      {/* <Reitems /> */}
    </div>
  );
};

export default References;
