import React from 'react';

const projectData = [
  
  {
    name: "Metallic LPDA (log-periodic dipole array)",
    description: "Log-periodic dipole array (LPDA) antenna is a powerful directional antenna. It's very powerful at picking up weak signal in one particular direction.",
    image: "/assets/antenna.png",
    tags: ["electronic warfare", "wideband monitoring", "jamming"," high power"]
  },
  {
    name: "Helical Antenna",
    description: "The simplest antenna which offers wideband matching, and high-power handling capability.It consists of a helix-shaped conductor, making it well-suited for applications requiring circular polarization and broad bandwidth.",
    image: "/assets/antenna2.png",
    tags: ["High power", "jamming,", "high gain"]
  },
  {
    name: "Distance Measuring Antenna",
    description: "Stack dipole, vertically polarized, omnidirectional antenna designed to get the desired gain while having good impedance matching.",
    image: "/assets/antenna3.png",
    tags: ["Omni-directional", "Vertically Polarized", "Military Aeronautical Radio,"]
  },
  {
    name: "Horn  Antenna",
    description: "A low loss, high gain antenna with directional radiation pattern good for jamming purposes.",
    image: "/assets/antenna1.png",
    tags: ["High power","Directional", "High Gain"]
  },
  {
    name: "Printed LPDA",
    description: "A wideband antenna with moderate gain good for RF Sensing.",
    image: "/assets/antenna11.png",
    tags: ["Planar", "RF Sensing", "Moderate Gain"]
  },
  {
    name: "Dual-band Helical Antenna",
    description: "The simplest antenna which offers wideband matching, and high-power handling capability.",
    image: "/assets/antenna6.png",
    tags: ["High power", "Jamming", "High gain"]
  },
  {
    name: "Scanner Antenna",
    description: "Wideband monopole antenna that is primarily used for spectrum monitoring.",
    image: "/assets/antenna7.png",
    tags: ["Wide band", "Spectrum monitoring", "Direction finding"]
  },
  {
    name: "Blade Antenna",
    description: "Aerodynamic antenna that are typically mounted onto the exterior of an aircraft to reduce drag while maintaining optimal communication and signal transmission",
    image: "/assets/antenna9.png",
    tags: ["Wideband", "Ground to Air Communication,", "Air to Ground communication","Round to Ground communication"]
  },
  {
    name: "Spiral Antenna",
    description: "Circular polarized, wideband antenna having direction pattern is good candidate for direction finding applications.",
    image: "/assets/antenna8.png",
    tags: ["Search and Rescue", "Direction finding", "Navigation"]
  }

];

const ProjectCard = ({ project }) => (
  <div className="bg-gray-50 h-6/7 shadow-md  overflow-hidden">
    {/* Image */}
    <div className=" overflow-hidden">
      <img
        alt={project.name}
        src={project.image}
        className="w-full h-full object-cover"
      />
    </div>
    
    {/* Content */}
    <div className="p-4">
      {/* Title */}
      <h2 className="text-xl font-bold text-gray-800 mb-2">{project.name}</h2>
      
      {/* Description */}
      <p className="text-gray-600 text-sm  mb-4">{project.description}</p>
      
      {/* Tags */}
    <div className="flex flex-wrap gap-2">
        {project.tags.map((tag, index) => (
          <span
            key={index}
            className="bg-amber-100 text-amber-800 text-xs font-semibold px-2 py-1 rounded-full"
          >
            {tag}
          </span>
        ))}
      </div>
    </div>
  </div>
);

const Projects = () => {
  return (
    <><div
      className="flex items-center justify-center md:h-[100vh] h-[60vh] bg-cover  relative"
      style={{ backgroundImage: "url('/assets/projects.jpg')" }}
    >
      <div className="flex flex-col items-start mt-40 text-white">
        <h1 className="text-[65px] font-extrabold animate-bounce animate-infinite">
      
        </h1>
      </div>
    </div><div className=" md:px-4 pt-8">
        <h2 className="md:text-4xl  font-bold text-center mb-8 text-amber-500">Our Antenna Products</h2>
        <div className="grid grid-cols-1   md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 px-4 md:px-12 mb-12">
          {projectData.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div></>
  );
};

export default Projects;