
import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { Antenna, Cpu, Zap, Award } from "lucide-react"
import Technology from "./Technology";

const services = [
  {
    title: "Antenna – the most critical component!",
    description: "It's not enough to just enable a device with any wireless connection. Today, consumers and industrial users expect connectivity with uncompromised quality. Clearly, wireless has become the most significant feature in consumer gadgets and industrial IoT devices. This, in turn, makes a carefully designed antenna the most critical component in your product!",
    icon: Antenna
  },
  {
    title: "What's the Product Makers Antenna Challenge?",
    description: "As a device maker, how can you ensure that the antenna inside your product delivers superior wireless connectivity and user-experience? Designing an antenna is challenging – how to develop a high-performing antenna without missing out the market window, and blowing up your budget?",
    icon: Cpu
  },
  {
    title: "Hire an Antenna Design Specialist!",
    description: "Building a high-performance antenna for a connected product requires specialist expertise in antenna design, testing, measurements, and simulation. It requires specialist tools, laboratory environments, and anechoic chamber.",
    icon: Zap
  },
  {
    title: "Custom antenna design is our super-power!",
    description: "Since 2015, Radientum has been delivering high-performance antenna design and consulting services for companies, startup device makers, and the world-leading brands. Hundreds of products.",
    icon: Award
  }
]

const FadeInWhenVisible = ({ children }: { children: React.ReactNode }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 }
      }}
    >
      {children}
    </motion.div>
  )
}

export default function Services() {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  

  return (
    <div className="overflow-hidden">
      <div
        className="relative flex items-center justify-center h-[60vh] md:h-[100vh] bg-cover bg-center"
        style={{
          backgroundImage: "url('/assets/services.jpg')"
        }}
      >
        <div className="absolute inset-0  bg-opacity-50" />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="relative text-white text-center z-10"
        >
          
        </motion.div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <FadeInWhenVisible>
        <h1 className="text-2xl md:text-5xl text-amber-500 text-center font-extrabold mb-4">Services</h1>
        <p className="text-xl md:text-4xl text-slate-600 text-center pb-12 font-medium">Empowering the Wireless Future</p>
          {/* <h2 className="text-3xl md:text-4xl font-bold text-center text-amber-500 mb-8">
            Wireless Device Revolution
          </h2> */}
        </FadeInWhenVisible>

        <FadeInWhenVisible>
          <div className="max-w-3xl mx-auto text-center text-gray-600 space-y-6">
            <p>
              Device makers are launching dozens of new wireless products in the markets every day – tens of billions of devices are now connected wirelessly. And, we are still in the starting blocks of the Internet of Things!
            </p>
            <p>
              The Internet of Things, cars, and wearables has ignited a proliferation of connected devices in all sectors of life, business, and the industry. However, you can expect to see accelerated growth as device makers are seeking new revenues, industries are aiming for higher efficiencies, and consumers are craving for faster connections, and more amazing experiences. So, ever more wireless devices are required with even better connectivity!
            </p>
            <p>
              However, competition is fierce, and, as you know, it is not easy to develop a great wireless device – it's only as good as the quality of the connection. A flaky, dragging wireless connection ruins the user-experience in a split moment, the user abandons the product, and – in the worst scenario – gives bad online ratings, which can simply stop your sales long before the break-even point.
            </p>
          </div>
        </FadeInWhenVisible>
      </div>

      <div className=" py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {services.map((service, index) => (
              <FadeInWhenVisible key={index}>
                <div className="bg-slate-800 border-4 border-amber-500  rounded-lg shadow-lg p-6 h-full flex flex-col">
                  <div className="flex items-center mb-4">
                    <service.icon className="h-8 w-8 text-amber-500 mr-3" />
                    <h3 className="text-xl font-semibold text-white">{service.title}</h3>
                  </div>
                
                  <p className="text-white text-md flex-grow">{service.description}</p>
                </div>
              </FadeInWhenVisible>
            
            ))}
          </div>
      
        </div>
        
      </div>
      <div className="items-center justify-center">
      <Technology />
      </div>
   
    </div>
    
  )
}