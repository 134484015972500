import React, { useEffect } from "react"; 
import Slider from "./slider";
import Antennatechslider from "./headerslider"
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Globe, Users, Zap } from 'lucide-react';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <div
          className="flex items-center justify-center"
          style={{
            height: "100vh",
            position: "relative",
          }}
        >
          {/* Gradient Overlay */}
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 191, 0, 0.2), rgba(255, 140, 0, 0.3))`,
              zIndex: 1,
            }}
          ></div>

          {/* GIF Image */}
          <img
            src="/assets/header.gif"
            alt="Antenna Animation"
            className="absolute inset-0 w-full h-full object-cover"
            style={{
              zIndex: 0,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
         
          <div className="container mx-auto px-4 relative z-10">
            <div className="md:w-1/2">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="text-white"
              >
                <h1 className="text-5xl md:text-6xl font-extrabold mb-4">
                  Innovation, Empowerment, Growth
                </h1>
                <h5 className="text-xl md:text-2xl font-bold">
                  Empowering the Invisible Pathways of Communication
                </h5>
              </motion.div>
              
            </div>
            
          </div>
          
        </div>
        <Antennatechslider />
        <div className="bg-amber-500 py-12 gap-12">
          
          <div className="mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center justify-between gap-12">
              <div className="md:w-2/3 mb-8 md:mb-0">
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  className="text-white"
                >
                  <h2 className="md:text-3xl text-xl font-sans  sm:text-center md:text-left font-bold text-slate-700 mb-4 ">
                    Revolutionizing Antenna Design
                  </h2>
                  <p className="mb-6 text-justify font-semibold">
                    Wireless connection is the most critical feature of your
                    connected device. And, the antenna is the crucial component ensuring robust RF performance and superb
                    user-experience. Yet, designing an antenna is a demanding process, which affects your time to market and
                    product development costs. Radientum's Antenna Design turn-key service fits into your product development
                    process – so that you can concentrate on developing a superior device while we bring in our world-class
                    antenna design expertise, equipment, and facilities.
                  </p>
                  <button className="bg-slate-600 text-white hover:animate-pulse px-6 py-2 rounded-full font-semibold  transition duration-300">
                    Learn More
                  </button>
                </motion.div>
              </div>
              <div className="md:w-1/2">
                <motion.img
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  src="https://th.bing.com/th/id/R.6feaa3730d5c22426f2546f86b8d65ad?rik=EEiag%2bPLFewk9g&pid=ImgRaw&r=0"
                  alt="Antenna Illustration"
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-slate-500 py-12 gap-12">
          <div className="mx-auto px-4">
            <div className="flex flex-col-reverse md:flex-row items-center justify-between gap-12">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <motion.img
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  src="https://th.bing.com/th/id/OIP.XbGy_y7tEAzrXTEo-3txbwHaEK?rs=1&pid=ImgDetMain"
                  alt="Wireless Performance Illustration"
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              </div>
              <div className="md:w-2/3">
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  className="text-gray-800"
                >
                  <h2 className="md:text-3xl text-xl font-sans  sm:text-center md:text-left font-bold text-amber-500 mb-4 ">
                    Optimizing Wireless Performance
                  </h2>
                  <p className="mb-6 text-justify text-white font-semibold">
                    In today's interconnected world, optimizing wireless performance is crucial for ensuring seamless 
                    communication and connectivity. Our advanced antenna solutions go beyond basic design, incorporating 
                    cutting-edge technologies and methodologies to maximize signal strength, minimize interference, and 
                    enhance overall system efficiency. By leveraging state-of-the-art simulation tools and real-world 
                    testing environments, we deliver antennas that not only meet but exceed the demanding requirements 
                    of modern wireless devices and networks.
                  </p>
                  <button className="bg-amber-500 text-white hover:animate-pulse px-6 py-2 rounded-full font-semibold transition duration-300">
                    Explore Solutions
                  </button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        {/* New Global Expertise Section */}
        <div className=" bg-amber-50 py-16 xl:py-28">
  <div className="container mx-auto px-4">
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
          <h2 className="text-2xl md:text-4xl font-serif  text-center font-normal text-slate-800 mb-12" style={{ fontFamily: "'Playfair Display', serif" }}>
                Global Expertise, Local Impact
              </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
      
        <div className="text-center bg-white shadow-lg p-6 rounded-lg transition transform hover:scale-105 hover:shadow-xl">
          <Globe className="mx-auto text-amber-900 mb-2 animate-pulse" size={58} fill="white" />
          <h3 className="text-2xl italic mb-2  text-amber-900">Worldwide Presence</h3>
          <p className="text-gray-700  text-center leading-relaxed">
            With operations spanning across continents, we bring global insights to local challenges.
          </p>
        </div>
        
        <div className="text-center bg-white shadow-lg p-6 rounded-lg transition transform hover:scale-105 hover:shadow-xl">
          <Users className="mx-auto text-amber-900 mb-2 animate-pulse" size={58} />
          <h3 className="text-2xl italic mb-2 text-amber-900">Expert Team</h3>
          <p className="text-gray-700  text-center leading-relaxed">
            Our diverse team of engineers and researchers pushes the boundaries of antenna technology.
          </p>
        </div>
      
        <div className="text-center bg-white shadow-lg p-6 rounded-lg transition transform hover:scale-105 hover:bg-slate-400 hover:shadow-xl">
          <Zap className="mx-auto text-amber-900 mb-2 animate-pulse" size={58} />
          <h3 className="text-2xl italic mb-2 text-amber-900">Cutting-edge Solutions</h3>
          <p className="text-gray-600 text-center leading-relaxed">
            We deliver innovative antenna designs that power the next generation of wireless devices.
          </p>
        </div>
      </div>
    </motion.div>
  </div>
</div>
        <div className="bg-gray-800 border-4 border-amber-50 py-10 text-center relative overflow-hidden">
          <div
            className="absolute inset-0 opacity-5 animate-pulse"
            style={{
              backgroundImage: `url('https://th.bing.com/th/id/OIP.fLho_x71v-HKF-GRTK1TSAHaHa?w=202&h=202&c=7&r=0&o=5&dpr=1.5&pid=1.7')`,
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top', 
            }}
          ></div>
          <h2 className="text-3xl text-amber-500 italic font-serif font-normal mb-4 relative z-10">
            Let Us Craft The Perfect Antenna Solutions For You!
          </h2>
          <Link to="/contact" className="bg-amber-50 text-amber-500 px-6 py-2 rounded-full font-semibold hover:bg-amber-100 relative z-10">
            Get in Touch
          </Link>
        </div>

        <div>
          <Slider />
        
        </div>
      </div>
    </>
  );
};

export default Home;