import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="overflow-hidden">
      {/* Hero Section */}
      <div
        className="flex items-center justify-center h-[60vh] sm:h-80 md:h-[95vh] xl:h-[55vh] bg-cover bg-center relative"
        style={{
          backgroundImage: "url('/assets/contact.jpg')",
        }}
      >
        {/* <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider mt-16 sm:mt-24 animate__animated animate__fadeInLeft text-center px-4">
          Feel Free To Contact
        </h1> */}

        {/* <Link
          activeClass="active"
          to="container"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2 cursor-pointer"
        >
          <i className="fas fa-angle-down text-white text-3xl animate__animated animate__bounce animate__repeat-3"></i>
        </Link> */}
      </div>

      {/* Contact Information Section */}
      <div className="container mx-auto py-12 px-4">
        <h5
          className="text-center text-amber-500 text-xl sm:text-2xl md:text-4xl font-semibold  mb-6"
          data-aos="fade-up"
        >
          Contact Us
        </h5>
        <p
          className="text-center text-base sm:text-lg text-gray-600 mb-10"
          data-aos="fade-up"
        >
          We've embraced simpler and more efficient methods <br />
          to connect with you and address any questions you may have.
        </p>

        {/* Contact Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6" data-aos="fade-up">
          {/* Address Card */}
          <a
            href="https://maps.app.goo.gl/BBpErJB1MnGctqed8"
            target="_blank"
            rel="noopener noreferrer"
            className="transform transition-transform duration-300 hover:scale-105"
          >
            <div className="bg-gradient-to-b from-amber-500 to-amber-300 rounded-lg shadow-lg p-6 md:p-8 text-center h-full">
              <h4 className="text-lg md:text-2xl font-bold mb-4">Address</h4>
              <i className="fa-solid fa-location-dot text-gray-700 text-3xl mb-4"></i>
              <p className="text-black text-sm md:text-base">
                First Floor, National Science and Technology Park,<br />
                NUST, H-12, Islamabad, Pakistan
              </p>
            </div>
          </a>

          <a
            href="mailto:contact@antennatec.com"
            className="transform transition-transform duration-300 hover:scale-105"
          >
            <div className="bg-gradient-to-b from-amber-500 to-amber-300 rounded-lg shadow-lg p-6 md:p-8 text-center h-full">
              <h4 className="text-lg md:text-2xl font-bold mb-4">Email</h4>
              <i className="fa-solid fa-envelope text-slate-700 text-3xl mb-4"></i>
              <p className="text-slate-700 text-sm md:text-lg ">contact@antennatec.com</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
