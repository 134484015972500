import React from 'react';
import { Mail, Phone } from 'lucide-react';

const navItems = [
  { name: "Home", path: "/" },
  { name: "Products", path: "/Projects" },
  { name: "Services", path: "/Service" },
  { name: "References", path: "/References" },
  { name: "Contact Us", path: "/Contact" },
];

export default function WaveFooter() {
  return (
    <footer className="relative text-white overflow-hidden bg-gray-900">
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        
        <div className="py-8 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="space-y-4">
            <img src="assets/logoo.png" alt="Logo" className="h-32 md:ml-10 ml-24" />
            <p className="text-gray-300 text-sm">
              Connecting the world with advanced antenna technologies for seamless communication. Our custom solutions are engineered for precision, performance, and reliability.
            </p>
          </div>

          <div className='mt-8'>
            <h4 className="text-lg font-semibold mb-4 text-amber-500">Quick Links</h4>
            <ul className="space-y-2 text-sm">
              {navItems.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className="text-gray-300 hover:text-white transition-colors duration-200 hover:underline"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className='mt-8'>
            <h4 className="text-lg font-semibold mb-4 text-amber-500">Our Services</h4>
            <ul className="space-y-2 text-sm">
              {['5G Solutions', 'IoT Connectivity', 'Satellite Communications', 'Custom Antenna Design'].map((service) => (
                <li key={service}>
                  <a href="/" className="text-gray-300 hover:text-white transition-colors duration-200 hover:underline">
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className='mt-8'>
            <h4 className="text-lg font-semibold mb-4 text-amber-500">Contact Us</h4>
            <address className="not-italic text-gray-300 space-y-2 text-sm">
              First Floor, National Science & <br /> Technology Park,
              NUST, H-12,<br /> Islamabad, Pakistan
              <a href="mailto:contact@antennatec.com" className="flex items-center space-x-2 hover:text-white transition-colors duration-200">
                <Mail size={16} />
                <span>contact@antennatec.com</span>
              </a>
              <a href="tel:+923032306688" className="flex items-center space-x-2 hover:text-white transition-colors duration-200">
                <Phone size={16} />
                <span>+92 303 2306688</span>
              </a>
            </address>
          </div>
        </div>

        <div className="w-full py-6 text-center text-white text-md font-bold font-sans mt-8 relative z-20">
          <p>&copy; {new Date().getFullYear()} Antenna. All rights reserved.</p>
        </div>
      </div>

      <div className="overflow-hidden absolute bottom-0 left-0 w-full">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(100,116,139,0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(100,116,139,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="6" fill="rgba(245,158,11,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="9" fill="rgba(100,116,139,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="12" fill="rgba(245,158,11,0.4)" />
          </g>
        </svg>
      </div>

      <style jsx>{`
        .waves {
          position: relative;
          width: 100%;
          height: 100px;
          margin-bottom: -7px;
          min-height: 100px;
          max-height: 150px;
        }
        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
        }
        .parallax > use:nth-child(1) { animation-delay: -2s; animation-duration: 7s; }
        .parallax > use:nth-child(2) { animation-delay: -3s; animation-duration: 10s; }
        .parallax > use:nth-child(3) { animation-delay: -4s; animation-duration: 13s; }
        .parallax > use:nth-child(4) { animation-delay: -5s; animation-duration: 20s; }
        .parallax > use:nth-child(5) { animation-delay: -6s; animation-duration: 25s; }
        @keyframes move-forever {
          0% { transform: translate3d(-90px,0,0); }
          100% { transform: translate3d(85px,0,0); }
        }
      `}</style>
    </footer>
  );
}
