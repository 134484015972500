import React from "react";

const Reitems = () => {
  // Sample references array
  const references = [
    {
      title: "Project 1",
      description: "This project involved designing a custom antenna for a leading telecom company.",
      image: "/assets/UI2.jpg",
      lastUpdated: "Last updated 1 day ago",
    },
    {
      title: "Project 2",
      description: "Developed an integrated antenna system for a multi-national corporation.",
      image: "/assets/UI2.jpg",
      lastUpdated: "Last updated 3 days ago",
    },
    {
      title: "Project 3",
      description: "Designed and tested RF circuits for innovative wireless solutions.",
      image: "/assets/UI2.jpg",
      lastUpdated: "Last updated 5 days ago",
    },
  ];

  return (
    <>
     

      <div className="container mx-auto  mb-5">
        {/* Added a new heading for the cards section */}
        <div className="flex flex-col items-center">
        <h1 className="mt-48 text-center text-3xl font-bold text-amber-500 animate__animated animate__fadeInUp">
          Antenna reference projects
        </h1>
        <br />
        <div className="my-6">
          <p className="text-center text-gray-400 animate__animated animate__fadeInUp">
            We have designed custom antennas and integrated standard antennas
            for customers ranging <br />
            from startups to multi-national corporations. We have successfully
            delivered more than 200 antenna <br />
            and RF projects and helped more than 100 companies in all things
            antenna. Here you can get to know us through <br />
            our past projects and see what customers say about our competence.
            Below are some of our public references.
          </p>
        </div>
      </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {references.map((reference, index) => (
            <div className="col" key={index}>
              <div className="card h-100 border border-gray-200 rounded-lg shadow-lg">
                <img
                  src={reference.image}
                  className="card-img-top rounded-t-lg"
                  alt={reference.title}
                />
                <div className="card-body p-4">
                  <h5 className="text-lg font-semibold">{reference.title}</h5>
                  <p className="text-gray-700">
                    {reference.description}
                  </p>
                </div>
                <div className="card-footer bg-gray-100 p-2">
                  <small className="text-gray-500">{reference.lastUpdated}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Reitems;
