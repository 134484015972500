import React, { useEffect } from "react";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { BorderBeam } from "@stianlarsen/border-beam";

const Technology = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="w-full">
      {/* Wireless Technologies Section */}
      <div className="flex flex-col items-center mt-10 px-4">
        <h1 className="text-center text-2xl md:text-3xl font-bold text-gray-800 mb-5" data-aos="fade-up">
          Wireless Technologies
        </h1>
        <div className="w-full md:w-4/5" data-aos="fade-up">
          <p className="text-center text-gray-800 mb-3">
            We help customers develop products with superb wireless performance – whatever the technology or product is.
            We work in all industry sectors that require wireless communication. Our offerings cover all steps of the
            product life-cycle, from concept to design, verification, and mass production quality control.
          </p>
          <p className="text-center text-gray-800 mb-3">
            We specialize in developing and integrating compact antennas for challenging products and environments.
          </p>
          <p className="text-center text-gray-800 mb-3">
            On this page, you&apos;ll find more information about specific communication technologies and how we can help
            when they are used in your products.
          </p>
        </div>
      </div>

      {/* Technologies Cards with Glassmorphism and BorderBeam */}
      <div className="flex justify-center mx-auto py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-8" data-aos="fade-up">
          {[
            { title: "Cellular", text: "We develop integrated multiband antenna solutions for cellular networks that can meet the toughest operator requirements. You can achieve worldwide coverage for small or metallic devices." },
            { title: "IoT", text: "We develop integrated antenna solutions for applications requiring high performance and low BOM for various IoT technologies including Lora, Sigfox, NB-IoT, Zigbee, and many more." },
            { title: "Bluetooth", text: "Bluetooth or other 2.4GHz ISM products are often battery powered and small. High efficiency antennas play a critical role while keeping the size compact." },
            { title: "5G mmWave", text: "We provide custom antenna arrays and product integration for 5G's higher frequency cellular networks with antenna array solutions." },
            { title: "NFC/RFID", text: "We develop integrated multiband antenna solutions for cellular networks that can meet the toughest operator requirements for small or metallic devices." },
            { title: "Wi-Fi", text: "We design multiband antenna solutions for Wi-Fi networks to meet high performance needs in various industrial and consumer products." },
            { title: "GPS", text: "We develop antennas for all positioning systems such as GPS, Glonass, Beidou, with options for single or dual band needs." },
            { title: "UWB", text: "We design high performance, high accuracy antennas for UWB data and positioning systems to achieve excellent results without compromising on design." },
            { title: "Antenna Fabrication", text: "We specialize in the fabrication of custom antennas tailored to meet specific client requirements, ensuring optimal performance across a wide range of applications." },
          ].map((tech, index) => (
            <div key={index} className="relative w-full h-full max-h-sm max-w-sm mx-auto" style={{ position: "relative" }}>
              {/* BorderBeam effect */}
              <BorderBeam colorFrom="#ffd700" colorTo="#ffd700" borderWidth={4.5} size={200} duration={10} />

              {/* Card Content */}
              <div className="glass-effect h-full w-full bg-gradient-to-br from-slate-700 via-slate-800 to-slate-900 p-4 rounded-md flex flex-col justify-center items-center">
                <h2 className="text-lg font-bold text-white mb-2">{tech.title}</h2>
                <p className="text-white text-center text-sm font-semibold">{tech.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technology;
