import React from 'react'
import Slider from 'react-infinite-logo-slider';

const slider = () => {
  return (
    <div>
        
 <div className=" bg-white relative z-0">
  <div className=" ">
    <Slider
      width="218px"
      duration={25}
      pauseOnHover={true}
      blurBorders={true}
      blurBoderColor={'#fff'}
    >
      <Slider.Slide>
        <img
          src="assets/rf.jpg"
          alt="any"
          className="w-36"
        />
      </Slider.Slide>
      <Slider.Slide>
        <img src="assets/rwr.jpeg" alt="any2" className="w-36 bg-transparent" />
      </Slider.Slide>
      <Slider.Slide>
        <img src="assets/rapidev.jpeg" alt="any3" className="w-32 h-1/2" />
      </Slider.Slide>
      <Slider.Slide>
        <img src="assets/bcube.jpeg" alt="any3" className="w-28 h-1/2" />
      </Slider.Slide>
      <Slider.Slide>
        <img src="assets/nastap.jpeg" alt="any3" className="w-26 h-1/2" />
      </Slider.Slide>
      <Slider.Slide>
        <img src="assets/Nstp.jpeg" alt="any3" className="w-30 h-1/2" />
      </Slider.Slide>
    </Slider>
  </div>
</div>
    </div>
  )
}

export default slider
