import React, { useState, useEffect } from 'react';

const testimonials = [
  {
    id: 1,
    name: 'Pam',
    role: 'CEO at COMPANY',
    quote: "Took some convincing, but now that we're on COMPANY, we're never going back.",
  },
  {
    id: 2,
    name: 'Jeremy',
    role: 'CFO at COMPANY',
    quote: "SO SO SO HAPPY WE FOUND YOU GUYS!!!! I'd bet you've saved me 100 hours so far.",
  },
  {
    id: 3,
    name: 'Daniel',
    role: 'CEO at COMPANY',
    quote: "I'd be lost without COMPANY's in-depth analysis. The ROI is ENORMOUS for us.",
  },
  {
    id: 4,
    name: 'Fernando',
    role: 'CEO at COMPANY',
    quote: "It's just the best. Period.",
  },
  // Add more testimonials as needed
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(goToNext, 4000); // Auto slide every 4 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="relative w-full max-w-7xl mx-auto px-4 py-16">
      <div className="flex items-center justify-center h-48">
        {testimonials.map((testimonial, index) => {
          const position = (index - currentIndex + testimonials.length) % testimonials.length;
          let zIndex, transform, opacity, clipPath, bgColor;

          if (position === 0) {
            zIndex = 30;
            transform = 'translateX(0%) rotate(0deg) scale(1)';
            opacity = 1;
            clipPath = 'none';
            bgColor = 'bg-amber-500';
          } else if (position === 1) {
            zIndex = 20;
            transform = 'translateX(88%) rotate(5deg) scale(0.9)';
            opacity = 1;
            clipPath = 'inset(0 0% 0 0)';
            bgColor = 'bg-white';
          } else if (position === testimonials.length - 1) {
            zIndex = 20;
            transform = 'translateX(-88%) rotate(-5deg) scale(0.9)';
            opacity = 1;
            clipPath = 'inset(0 0 0 0%)';
            bgColor = 'bg-white';
          } else {
            zIndex = 10;
            transform = 'translateX(150%) rotate(10deg) scale(0.8)';
            opacity = 0;
            clipPath = 'none';
            bgColor = 'bg-white';
          }

          return (
            <div
              key={testimonial.id}
              className={`absolute w-full max-w-lg h-full transition-all duration-300 ease-in-out ${bgColor} p-6 rounded-lg shadow-lg`}
              style={{ zIndex, transform, opacity, clipPath }}
            >
              <div className="flex flex-col items-center text-center">
                <div className="relative w-24 h-24 mb-1 rounded-full overflow-hidden">
                  <div className="absolute inset-0 rounded-full bg-gradient-to-br from-slate-700 to-sky-600 opacity-80"></div>
                </div>
                <p className={`text-lg mb-4 ${position === 0 ? 'text-white' : 'text-gray-700'}`}>
                  "{testimonial.quote}"
                </p>
                <p className={`font-semibold ${position === 0 ? 'text-white' : 'text-gray-900'}`}>
                  - {testimonial.name}, {testimonial.role}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-2 left-0 right-0 flex justify-center z-30">
        <button
          onClick={goToPrevious}
          className="bg-white text-4xl font-bold px-4 py-2 rounded-full shadow-lg mr-2 hover:bg-slate-200 transition duration-300 flex items-center justify-center"
        >
          <span className="font-extrabold text-xl">&lt;</span>
        </button>
        <button
          onClick={goToNext}
          className="bg-white text-4xl font-bold px-4 py-2 rounded-full shadow-lg ml-2 hover:bg-slate-200 transition duration-300 flex items-center justify-center"
        >
          <span className="font-extrabold text-xl">&gt;</span>
        </button>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
