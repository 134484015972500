import React from 'react';
import Marquee from "react-fast-marquee";
import { 
  Radio, Wifi, Zap, Globe, Shield, Cloud, Cpu, Smartphone, BarChart2, Settings, Server, Network, 
  WifiOff, Target, ArrowRight, Layers, Grid, Repeat, BarChart, 
} from 'lucide-react';

const sectionsOne = [
  { name: "5G ANTENNA SOLUTIONS", icon: Radio },
  { name: "WIRELESS ANTENNA NETWORKS", icon: Wifi },
  { name: "BROADBAND ANTENNA SERVICES", icon: Zap },
  { name: "GLOBAL ANTENNA CONNECTIVITY", icon: Globe },
  { name: "ANTENNA SECURITY SYSTEMS", icon: Shield },
  { name: "CLOUD-BASED ANTENNA CONTROL", icon: Cloud },
  { name: "IoT ANTENNA PLATFORMS", icon: Cpu },
  { name: "MOBILE ANTENNA TECHNOLOGIES", icon: Smartphone },
  { name: "ANTENNA NETWORK ANALYTICS", icon: BarChart2 },
  { name: "TELECOM ANTENNA INFRASTRUCTURE", icon: Settings },
];

const sectionsTwo = [
  { name: "DATA CENTER ANTENNAS", icon: Server },
  { name: "SATELLITE ANTENNA COMMUNICATIONS", icon: Network },
  { name: "MICROWAVE ANTENNA SYSTEMS", icon: WifiOff },
  { name: "RF ANTENNA DESIGN", icon: Target },
  { name: "DIRECTIONAL ANTENNAS", icon: ArrowRight },
  { name: "MULTIBAND ANTENNA SOLUTIONS", icon: Layers },
  { name: "SMART ANTENNA ARRAYS", icon: Grid },
  { name: "ADAPTIVE ANTENNA SYSTEMS", icon: Repeat },
  { name: "MIMO ANTENNA TECHNOLOGIES", icon: BarChart },
];

const SliderItem = ({ name, Icon }) => (
  <div className="flex items-center whitespace-nowrap px-8 text-white transition-all duration-300 hover:text-amber-400">
    <Icon className="mr-2 h-5 w-5 text-amber-400" />
    <span className="font-semibold">{name}</span>
  </div>
);

const ScrollSlider = ({ sections, direction }) => {
  return (
    <div className="bg-slate-800 py-3">
      <Marquee
        speed={40}
        direction={direction}
        gradient={false}
        pauseOnHover={true}
      >
        {sections.map((section, index) => (
          <SliderItem 
            key={index} 
            name={section.name} 
            Icon={section.icon}
          />
        ))}
      </Marquee>
    </div>
  );
};

const AntennaTechServicesSlider = () => {
  return (
    <div className="w-full bg-amber-50">
      <ScrollSlider sections={sectionsOne} direction="left" />
      <ScrollSlider sections={sectionsTwo} direction="right" />
    </div>
  );
};

export default AntennaTechServicesSlider;