import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Footer from './Components/footer';
import References from './Components/References';
import Projects from './Components/Projects';
import Services from './Components/Services';
import ScrollToTop from "./Components/ScrollToTop";
import Contact from "./Components/Contact";

function App() {
    // Use react-router-dom's useLocation hook to get the current route
    const location = useLocation();

    // Use useEffect to update the document title based on the current route
    useEffect(() => {
      // Define a default title
      let title = "Antenna | Home";

      // Extract the route name from the pathname
      const routeName = location.pathname.replace("/", "");

      // Map route names to their corresponding titles
      const routeTitleMap = {
        "": "Home",
        "References": "References",
        "Technology": "Technologies",
        "Service": "Services",
        "Contact": "Contact"
      };

      // Capitalize the first letter of the route name
      const capitalizedRouteName =
        routeTitleMap[routeName] || "404"; // Default to "404" if route not found
      // Set the document title using the route name
      document.title = `Antenna | ${capitalizedRouteName}`;
    }, [location.pathname]); // Re-run the effect when the pathname changes
  
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/References" element={<References />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/Service" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;